// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

.k-sidebar {
    background-color: white;
    .nav-link {
        background-color: white;
        color: $k-primary;
        .nav-icon {
            color: $k-primary;
        }
    }
    .nav-dropdown.open a.nav-link {
        color: $k-primary;
    }
    .nav-title {
        color: $k-secondary;
        font-size: 20px;
    }
    .nav-link:hover {
        background-color: $k-primary;
    }
    .nav-link.active {
        border-left: 10px solid $k-primary;
        background-color: white;
        color: $k-primary;
        font-weight: bold;
        .nav-icon {
            color: $k-primary;
        }
    }
}
.card-header {
    background-color: white;
}
.breadcrumb-item a {
    color: $k-primary;
}
.form-control {
    background-color: #f2f2f2;
    font-weight: bold;
}
.form-control::placeholder {
    color: black;
}
.form-group label {
    color: #717171;
}
.table-menu-item td {
    vertical-align: middle;
    img {
        border-radius: 5px;
    }
}

.tags-card {
    height: 400px;
    overflow-y: scroll;
}
.fixed-height {
    max-height: 600px;
    overflow-y: scroll;
}
.image-up-container{
    display: block;
    position: relative;
}
.image-swapper {
    .carousel-control-next,
    .carousel-control-prev {
        filter: drop-shadow(1px 2px 4px black);
    }
}
.hoverChooseFile {
    font-size: large;
    font-weight: bolder;
    display: flex;
    width: 100%;
    height: 100%;
    background: lightgrey;
    position: absolute;
    align-items: center;
    justify-content: center;
    opacity: 0;
}
.hoverChooseFile:hover {
    animation: makeVisible 2s infinite;
}

@keyframes makeVisible {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
}
.login-page {
    background-color: $k-white;
    display: flex;
	height: 100vh;
    justify-content: center;
    align-items: center;
    .bg-secondary {
        // background-color: $k-secondary;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-portion {
        height: 100%;
        .flex-view {
            display: flex;
            flex-direction: column;
            img {
                align-self: center;
            }
        }
        .card-body {
            padding: 3rem;
        }
        .card-footer {
            background-color: white;
            margin-bottom: 50px;
            border: 0;
        }
    }

$color_1: #000;
$color_2: #ffffff;
$color_3: #ec8620;
$color_4: #000000;
$color_5: #fff;
$font-family_1: "Segoe UI";
$font-family_2: "Segoe UI Italic";
$font-family_3: "Segoe UI Bold";
$font-family_4: "Segoe UI", sans-serif;
$background-color_1: #ec8620;
$border-color_1: #707070;
$border-color_2: #62aea9;
$border-color_3: #61a8a3;
$border-color_4: #ec8620;
$border-color_5: #d96d00;
$border-color_6: #000;
$border-color_7: #404040;

/*------------------------------------------------------------------
[Main Stylesheet]

Project     : p1-KelplatMenuHtml
Version     : 1.0
Author      : Md. Ekramul Hassan Avi
Author URI  : https://www.tigertemplate.com
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]

1.  reset css
2.  common css
3.  main css
0.  footer css

-------------------------------------------------------------------*/
/*
==========================================
1.  reset css
==========================================
*/
/*
==========================================
2.  common css
==========================================
*/
/* fonts size */
/* fonts size */
/* font weight */
/* font weight */
/* colors style */
/* colors style */
/* border radius */
/* border radius */
/* button style */
/* button style */
/*
==========================================
3.  main css
==========================================
*/
/*
==========================================
0.  footer css
==========================================
*/
.text_Segoe {
	font-family: $font-family_1;
	font-weight: normal;
}
.text_Segoe_i {
	font-family: $font-family_2;
	font-weight: normal;
	font-style: italic;
}
.text_Segoe_b {
	font-family: $font-family_3;
	font-weight: bold;
}
* {
	margin: 0;
	padding: 0;
}
body {
	font-family: $font-family_4;
	font-size: 25px;
	font-weight: 400;
	color: $color_1;
	position: relative;
}
a {
	text-decoration: none;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	cursor: pointer;
	display: inline-block;
	&:active {
		text-decoration: none;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		display: inline-block;
	}
	&:focus {
		text-decoration: none;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		display: inline-block;
	}
	&:hover {
		text-decoration: none;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		display: inline-block;
	}
}
button {
	text-decoration: none;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	cursor: pointer;
	display: inline-block;
}
h1 {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
h2 {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
h3 {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
h4 {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
h5 {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
h6 {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
p {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
ul {
	padding: 0;
	margin: 0;
	font-weight: 400;
	li {
		list-style-type: none;
	}
}
hr {
	padding: 0;
	margin: 0;
	font-weight: 400;
}
.common_style {
	.btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	.fs150 {
		font-size: 150px;
	}
	.fs50 {
		font-size: 50px;
	}
	.fs40 {
		font-size: 40px;
		line-height: 44px;
	}
	.fs35 {
		font-size: 35px;
	}
	.fs34 {
		font-size: 34px;
	}
	.fs24 {
		font-size: 24px;
	}
	.fs25 {
		font-size: 25px;
	}
	b {
		font-weight: 700;
	}
	strong {
		font-weight: 700;
	}
	.text-dark {
		color: $color_2 !important;
	}
	.text-orange {
		color: $color_3 !important;
	}
	.border-dark {
		border-color: $border-color_1 !important;
	}
	.rounded_50 {
		border-radius: 50px !important;
	}
}
.btn-success {
	background: #62aea9;
	border-color: $border-color_2;
	color: $color_4;
	&:hover {
		background: #61a8a3;
		border-color: $border-color_3;
		color: $color_4;
	}
}
.btn-orange {
	background: #ec8620;
	border-color: $border-color_4;
	color: $color_4;
	&:hover {
		background: #d96d00;
		border-color: $border-color_5;
		color: $color_4;
	}
}
.btn-dark {
	background: #000;
	border-color: $border-color_6;
	color: $color_5;
	&:hover {
		background: #404040;
		border-color: $border-color_7;
		color: $color_5;
	}
}
.gap_27 {
	gap: 27px;
}
.bullet_orange {
	padding-left: 30px;
	li {
		position: relative;
		z-index: 1;
		&:before {
			content: "";
			position: absolute;
			right: calc(100% + 10px);
			top: 50%;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: $background-color_1;
			transform: translateY(-50%);
		}
	}
}
.head_tagline {
	margin-top: -20px;
}
.row_gap_12 {
	margin-left: -6px;
	margin-right: -6px;
	>* {
		padding-left: 6px;
		padding-right: 6px;
	}
}

}


// new style
$color_1: #000;
$color_2: #707070;
$color_3: #EC8620;
$color_4: #000000;
$color_5: #fff;
$font-family_1: 'Segoe UI';
$border-color_1: #F1D2D2;


/* ***** Common Css **** */
/* ***** End Common Css **** */
/* **** Header **** */
/* **** End Header **** */
/* **** Menu Part **** */
/* **** End Menu Part **** */
/* **** Footer **** */
/* **** End Footer **** */
:root {
	scroll-behavior: initial;
}
* {
	margin: 0;
	padding: 0;
	text-decoration: none;
	list-style: none;
}
a {
	&:hover {
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
	}
	&:active {
		text-decoration: none;
	}
	transition: 0.4s linear;
	-webkit-transition: 0.4s linear;
	text-decoration: none;
}
ol {
	margin: 0;
	padding: 0;
}
ul {
	margin: 0;
	padding: 0;
}
img {
	display: block;
}
button {
	transition: 0.4s linear;
	-webkit-transition: 0.4s linear;
	text-decoration: none;
}
body {
	font-family: $font-family_1;
	font-size: 14px;
	font-weight: 400;
	color: $color_1;
	text-decoration: none;
}
.main-wrpper {
	height: 100%;
	width: 100%;
	overflow: hidden;
}
.container {
	max-width: 1790px;
}
header {
	position: relative;
	top: 0px;
	left: 0;
	right: 0;
	background: #fff;
	padding: 10px 0;
	z-index: 120;
	transition: 0.4s linear;
	-webkit-transition: 0.4s linear;
}
.logobox {
	img {
		width: 195px;
	}
}
.header-txt {
	border-left: 1px solid #707070;
	border-right: 1px solid #707070;
	h1 {
		font-size: 150px;
		font-weight: normal;
		color: $color_1;
		text-transform: uppercase;
		text-align: center;
		line-height: 150px;
		margin: 0;
	}
	p {
		font-size: 34px;
		font-weight: 400;
		color: $color_2;
		line-height: 40px;
		text-align: center;
		margin: 0;
		span {
			color: $color_3;
		}
	}
}
.head-rightbox {
	h6 {
		font-size: 25px;
		font-weight: normal;
		color: $color_2;
		line-height: 33px;
		text-align: center;
		margin: 0 0 20px;
		padding: 20px 0;
		border-bottom: 1px solid #707070;
	}
	p {
		font-size: 25px;
		font-weight: normal;
		color: $color_2;
		line-height: 33px;
		text-align: center;
		margin: 0 auto;
		max-width: 280px;
	}
}
.center-bar {
	margin: 20px 0 0;
	padding: 20px 0;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
	.row {
		align-items: center;
	}
}
.headnmbx {
	padding: 0 15px;
	h3 {
		margin: 0;
		font-size: 25px;
		font-weight: bold;
		color: $color_4;
		text-transform: uppercase;
		padding: 0 35px 0 0;
		border-right: 1px solid #707070;
		display: table;
	}
}
.taglinebox {
	p {
		margin: 0;
		font-size: 25px;
		font-weight: normal;
		color: $color_4;
		line-height: 28px;
		text-align: center;
	}
}
.btn-reserver {
	display: table;
	margin: 0 0;
	padding: 7px 35px;
	border-radius: 30px;
	background: #62AEA9;
	font-size: 25px;
	font-weight: bold;
	color: $color_1;
	text-align: center;
	text-transform: uppercase;
	&:hover {
		background: #000;
		color: $color_5;
	}
}
.menu-row {
	padding: 35px 0;
	border-bottom: 3px solid #000000;
	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		li {
			margin-right: 30px;
			&:last-child {
				margin: 0;
			}
			a {
				padding: 8px 15px;
				background: #000;
				width: 260px;
				text-align: center;
				display: block;
				border: 1px solid #707070;
				border-radius: 30px;
				color: $color_5;
				font-size: 25px;
				line-height: 33px;
				text-transform: uppercase;
				&:hover {
					color: $color_5;
					border-color: $border-color_1;
					background: #EC8620;
				}
			}
		}
		li.active {
			a {
				color: $color_5;
				border-color: $border-color_1;
				background: #EC8620;
			}
		}
	}
}
.btn-menubutton {
	margin: 20px 0 0;
	padding: 10px 0;
	align-items: flex-start;
	justify-content: flex-end;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
	display: none;
	.btn-bar {
		font-size: 28px;
		color: $color_1;
	}
}
.menu-row-block {
	.btn-close {
		display: none;
	}
}
.menu-wrp {
	padding: 30px 0;
	.row {
		margin: 0 -30px;
		.col-md-4 {
			padding: 0 30px;
		}
	}
}
.menu-block {
	margin: 0 0 30px;
	h2 {
		font-size: 50px;
		font-weight: bold;
		color: $color_4;
		line-height: 67px;
		padding: 0 0 10px;
		border-bottom: 3px solid #000000;
		margin: 0 0 10px;
	}
	ul {
		li {
			margin: 0 0 27px;
			&:last-child {
				margin: 0;
			}
			h3 {
				font-size: 35px;
				font-weight: bold;
				color: $color_1;
				margin: 0 0 10px;
				display: flex;
				width: 100%;
				span {
					font-weight: normal;
					margin: 0 0 0 auto;
					width: 100px;
					text-align: right;
					display: block;
				}
			}
		}
	}
}
.menu-img {
	margin: 0 0 30px;
	img {
		width: 100%;
	}
}
.includebox {
	display: flex;
	p {
		font-size: 25px;
		font-weight: normal;
		color: $color_1;
		line-height: 33px;
		font-style: italic;
		margin: 0 15px 0 0;
		min-width: 350px;
		max-width: 350px;
		width: 100%;
	}
	i {
		font-size: 25px;
		line-height: 33px;
	}
}
.menu-note-wrp {
	.row {
		align-items: center;
	}
}
.menu-dish-img {
	img {
		width: 100%;
	}
}
.menu-notedt {
	p {
		font-size: 32px;
		font-weight: normal;
		color: $color_1;
		line-height: 42px;
		margin: 0 0 15px;
		font-style: italic;
	}
	ul {
		li {
			font-size: 32px;
			font-weight: normal;
			color: $color_1;
			line-height: 42px;
			font-style: italic;
			padding: 0 0 0 30px;
			position: relative;
			&:before {
				position: absolute;
				content: '';
				width: 15px;
				height: 15px;
				border-radius: 100%;
				background: #EE8338;
				top: 15px;
				left: 0;
			}
		}
	}
}
footer {
	.footer-top {
		padding: 30px 0;
		border-top: 3px solid #000000;
	}
}
.footer-nm {
	h2 {
		margin: 0;
		font-size: 35px;
		font-weight: bold;
		color: $color_1;
		line-height: 33px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}
.footer-top {
	p {
		text-align: center;
		font-size: 25px;
		line-height: 36px;
		color: $color_1;
		margin: 0;
		a {
			color: $color_1;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	h6 {
		text-align: right;
		margin: 0;
		font-size: 35px;
		font-weight: bold;
		color: $color_1;
		line-height: 33px;
		letter-spacing: 1px;
	}
}

;@import "sass-embedded-legacy-load-done:0";