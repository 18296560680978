// Here you can add other styles

.m-0 {
    margin:0 !important;
}
.ml-0 {
    margin-left:0 !important;
}
.mr-0 {
    margin-right:0 !important;
}
.mt-0 {
    margin-top:0 !important;
}
.mb-0 {
    margin-bottom:0 !important;
}
.m-5 {
    margin: 5px !important;
}
.m-10 {
    margin: 10px !important;
}
.m-20 {
    margin: 20px !important;
}

.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}

.ml-5 {
    margin-left: 5px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-20 {
    margin-left: 20px !important;
}

.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-20 {
    margin-right: 20px !important;
}


;@import "sass-embedded-legacy-load-done:142";