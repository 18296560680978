// Variable overrides

$k-primary: #000000;
$k-secondary: #1cb6cb;
$k-white: #ffffff;
$k-green: #266171;

$secondary:$k-secondary;
$primary: $k-primary;
$white: $k-white;
$green  : $k-green;

;@import "sass-embedded-legacy-load-done:1";